@import "../../../lib/styles/variables";

.secondaryRoleSelect {
    :global .fs-select {
        // &__control {
        //     &--is-disabled {
        //         border-bottom: none;
        //     }
        // }

        &__value-container {
            display: flex;

            min-height: 2rem;
        }

        &__dropdown-indicator svg {
            fill: $colorGray;
        }
    }
}

@import "../../styles/vars";
@import "../../../lib/styles/variables";

.dashboardHome {
    position: relative;
    // Putting horizontal scroll responsibility here
    // rather than on stretching parent divs
    min-height: 100vh;

    :global {
        .ReactTable {
            width: $paymentsTableWidth;

            // Hacky fix for right padding problem
            // * https://blog.alexandergottlieb.com/overflow-scroll-and-the-right-padding-problem-a-css-only-solution-6d442915b3f4

            &::after {
                content: "";

                display: block;
                position: absolute;
                right: -2rem;

                width: 2rem;
                height: 1px;
            }

            .pagination-bottom {
                button.-btn {
                    padding: 0;
                }
            }

            .rt-expander {
                transform: scale(1.2);
            }

            .rt-th {
                font-weight: 400 !important;

                &:first-child {
                    border-right: none;
                }
            }
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: $paymentsTableWidth;
    padding: 0.5em 0.2em;
}

.iconContainer {
    width: 850px;
    margin-top: 2em;

    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
}

.legend {
    display: flex;
    justify-content: space-around;

    margin: 0.6em;
    padding: 0;

    font-size: 0.9em;
    font-weight: 700;

    list-style: none;
}

.legendItem {
    svg {
        margin-top: -0.2em;
    }

    span {
        margin-left: 0.5em;
    }
}

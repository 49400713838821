@import "../../styles/variables";

.formElement {
    position: relative;

    margin: 1rem 0 0.5rem 0;

    transition: color 0.2s, border-color 0.2s;

    --labelIconOffset: 1.4rem;
    --labelPosition: calc(var(--wrapperPadding) + 0.5rem);
    --labelPositionActive: calc(var(--wrapperPadding) - 1rem);
    --wrapperPadding: 1.5rem;

    .label {
        position: absolute;
        z-index: 1;
        left: 0;

        overflow: hidden;

        transform: translate(0, var(--labelPosition)) scale(1);
        transform-origin: top left;
        transition: color 200ms $smoothTransition 0ms, transform 200ms $smoothTransition;

        color: $colorGray;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        cursor: text;

        &.labelFocused {
            color: $colorPrimary;
        }

        &.labelOffset {
            left: var(--labelIconOffset);
        }

        &.labelDisabled {
            color: $colorMediumLightGray;

            cursor: default;

            &:hover {
                color: $colorMediumLightGray;
            }
        }

        &__textarea {
            transform: translate(1.1rem, calc(var(--labelPosition) + 0.6rem)) scale(1);
        }

        &:hover {
            color: darken($colorGray, 10%);
        }
    }

    &.active .label {
        transform: translate(0, var(--labelPositionActive)) scale(0.75);

        &.labelOffset {
            transform: translate(calc(var(--labelIconOffset) * -1), 0) scale(0.75);
        }

        // &__textarea {
        //     transform: translate(calc(var(--labelPositionActive) * -1), 0) scale(0.75);
        // }
    }
}

.inputWrapper {
    position: relative;

    height: 100%;
    padding-top: var(--wrapperPadding);

    &.inputFocused {
        &::after {
            content: "";

            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 2px;

            background: $colorPrimary;

            pointer-events: none;
        }
    }
}

.input {
    width: 100%;
    height: 100%;
    padding: 6px 0 7px;

    border-top: none;
    border-right: none;
    border-bottom: 2px solid $colorMediumLightGray;
    border-left: none;
    outline: 0;

    background-image: none !important;
    box-shadow: none;

    &.iconOffset {
        padding-left: var(--labelIconOffset);
    }

    &:disabled {
        border-color: $colorMediumLightGray;

        color: $colorMediumLightGray;

        cursor: default;
    }

    &:not(:disabled):hover {
        border-color: darken($colorMediumLightGray, 10%);
    }

    &::placeholder {
        color: $colorMediumLightGray;
    }
}

.textarea {
    composes: input;

    width: 100%;
    height: 8rem;
    padding: 1rem;

    border: 2px solid $colorMediumLightGray;
    border-radius: 0.3rem;
}

.hasError {
    background: rgba(153, 153, 153, 0.1);
}

.hidden {
    display: none;
}

.icon {
    position: absolute;
    top: calc(var(--wrapperPadding) + 0.6rem);
    left: 0.25rem;

    &.iconFocused {
        fill: $colorPrimary;
    }

    &.iconDisabled {
        fill: $colorMediumLightGray;
    }
}

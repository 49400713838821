@import "../../styles/colors.scss";
@import "../../../lib/styles/variables";

.plainMode {
    color: $blue !important;
    text-align: left;
    width: 100%;

    &:disabled {
        color: $colorGray !important;
    }

    &:not(:disabled) {
        cursor: pointer;
    }
}

.input {
    width: 100%;
    padding: 0.2em;

    border: none;
    outline: none;

    background-color: #e4f2fc;

    color: $blue;

    text-align: right;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }
}

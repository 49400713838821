@import "../../../../../lib/styles/variables";

.thumbnail {
    border-radius: 50%;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.options {
    display: flex;
    align-items: baseline;

    color: $colorGray;
}

.sectionHeading {
    margin: 2rem 0 2rem -26px;

    color: $colorGray;

    text-align: center;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.viewOptions {
    display: flex;
    align-items: center;

    span {
        margin: 0 0.5rem;

        font-size: 2rem;
        font-weight: 100;
    }
}

.option {
    all: unset;

    cursor: pointer;

    svg {
        fill: $colorGray;
    }

    svg:hover {
        fill: darken($colorGray, 20%);
    }

    &.active {
        svg {
            fill: darken($colorGray, 20%);
        }
    }
}

.searchSites {
    --wrapperPadding: 0.5rem;
}

.listContainer {
    width: 1200px;
}

.rolesContainer {
    width: 170px;
    margin-left: 2rem;
}

.secondaryRolesContainer {
    width: 210px;
}

.column {
    position: relative;
}

.headers {
    position: relative;
    left: 55px;
    padding: 1.5rem 0;

    color: $colorGray;
    font-size: 0.8rem;
    font-weight: 800;

    letter-spacing: 0.01rem;
    text-transform: uppercase;

    .spacer {
        display: inline-block;
        width: 250px;
    }

    span + span {
        margin-left: 2.3rem;
    }

    .secondaryRoleHeader {
        margin: 0 4rem 0 6.5rem;
    }

    .fee {
        margin-left: 3rem;
    }
}

.siteRow {
    display: flex;
    align-items: center;

    padding: 1.5rem 0;

    &:not(:first-of-type) {
        border-top: 1px solid $colorOffWhite;
    }
}

.lastPost {
    width: 80px;

    font-size: 0.9rem;
    white-space: nowrap;
}

.revShare {
    :global .fs-select-container {
        width: 8rem;

        .fs-select__control {
            font-size: 0.9rem;
        }
    }
}

.logosContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 12rem;

    @media only screen and (min-width: $mobile) {
        flex-direction: row;
        width: auto;
    }
}

.logoButtonWrap {
    width: 176px;
}

.logoButton {
    display: flex;
    flex-basis: 150px;
    flex-direction: column;
    margin: 1rem 0;
}

.logo {
    transition: all 0.2s;

    border-radius: 50%;

    &:hover {
        transform: scale(1.08);

        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

.logoSiteName {
    max-width: 150px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    color: $colorGrayDark;

    text-align: center;
    white-space: normal;
}

.nameAndLogoContainer {
    display: flex;
    position: relative;
    flex: 0 0 250px;
    align-items: center;

    text-align: center;
    white-space: nowrap;
}

.siteNameContainer {
    position: absolute;
    left: 2rem;

    margin: 0 0 0 2rem;

    text-align: left;
}

.siteName {
    margin: 0;

    color: $colorFSBlue;
    font-size: 0.9rem;
    font-weight: 900;
}

.title {
    width: 8rem;
    height: 1.3rem;

    color: $colorGray;
    font-size: 0.8rem;
    font-weight: 300;
}

.nameAndLogoContainerImage {
    position: relative;
}

.addSites {
    display: flex;
    align-items: center;

    &:hover label {
        color: darken($colorGray, 15%);
    }

    &:hover button:not(:disabled) {
        background-color: darken($colorLightGray, 15%);

        svg {
            fill: $colorWhite;
        }
    }
}

.addSitesText {
    margin-left: 0.5rem;

    transition: all 0.2s;

    color: $colorGray;
    font-size: 0.9rem;

    cursor: pointer;
}

.ssoLoading {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    min-height: 100vh;

    background: rgba(0, 0, 0, 0.2);

    color: $colorWhite;
    font-size: 1.5rem;
}

.flipRow {
    position: relative;

    width: 100%;
}

.flipRowSide {
    display: flex;
    position: absolute;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 0;

    backface-visibility: hidden;
    will-change: transform, opacity, z-index;
}

// ****************************************
//  Layout Helpers
// *****************************************/

// Vertical Gutters

@mixin owl($gutter, $horizontal: false) {
    /**
   * Apply to containers to get nice gutters between vertical items
   *
   * More Details: https://www.youtube.com/watch?v=w4skJXB03Ho&noredirect=1
   */

    @if $horizontal {
        // Its the "Lobotomized Owl" horizontally

        > * + * {
            margin-left: $gutter !important;
        }
    } @else {
        // Reset all first-child elements to have no vertical margin

        > * {
            margin-top: 0;
            margin-bottom: 0;
        }

        // Its the "Lobotomized Owl" vertically

        > * + * {
            margin-top: $gutter !important;
        }
    }
}

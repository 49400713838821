@import "../../styles/variables";

.background {
    min-height: 100vh;

    background: lighten($colorGrayLight, 30%);
}

.dashboardItemsContainer {
    display: flex;
    flex-wrap: wrap;

    padding: $gutter * 2 $gutter * 6;
}

.dashboardItem {
    display: flex;
    flex: 0 0 calc(100% / 3 - 2rem);
    align-items: center;
    justify-content: center;

    min-width: 150px;
    height: 8rem;
    margin: 0 2rem 2rem 0;
    padding: 0 0.5rem;

    border-top: 0.25rem solid $colorLightGray;

    background: $colorWhite;

    > svg {
        fill: $colorFSBlue;
    }

    .label {
        margin-left: 0.5rem;

        color: $colorFSBlue;
        font-size: 1.2rem;
        font-weight: 700;
    }

    &:hover {
        border-top: 0.25rem solid $colorFSBlue;

        & svg,
        & .label {
            fill: $colorPrimary;

            color: $colorPrimary;
        }
    }

    @media only screen and (max-width: $mobile) {
        margin: 0;

        & + * {
            margin-top: 2rem;
        }
    }
}

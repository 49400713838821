.site {
    display: flex;
    align-items: center;

    > div:first-of-type {
        width: 100%;
    }
}

.filterName {
    display: flex;
    align-items: center;

    width: 100%;

    font-size: 0.9rem;

    .filterNameInput {
        position: relative;
        margin: 0;
        width: 100%;

        --wrapperPadding: 0.4rem;
    }
}

:global {
    .ReactTable.fsTable .rt-tfoot {
        background: #ddd;
        font-weight: bold;
    }
}

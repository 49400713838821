@import "../../../../styles/variables";

.wmLink {
    display: flex;
    align-items: center;

    svg {
        margin-left: 0.5rem;
    }
}

.lightBlueFont {
    color: $colorPrimary;
}

@import "./variables";

/**
 * Global base overrides
 **/

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    overflow: inherit;

    color: $textColor;

    font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    scroll-behavior: smooth;
}

fieldset {
    padding: 0;

    border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    margin: 0;

    -webkit-appearance: none;
}

div[id^="__lpform"] {
    z-index: 2 !important;
}

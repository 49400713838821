@import "../../styles/variables";

@mixin lighten($color) {
    border-color: lighten($color, 5%);

    background-color: lighten($color, 5%);
}

.button {
    padding: 0.6em 1.2em;

    transition: all 0.2s;

    border: 2px solid $colorWhite;
    border-radius: 2em;

    color: $colorWhite;
    font-size: 1em;
    letter-spacing: 0.02em;
    white-space: nowrap;

    cursor: pointer;

    &:disabled {
        cursor: default;

        filter: brightness(125%);
    }

    > svg {
        margin-left: 0.3em;

        transition: fill 0.2s;
    }
}

.small {
    font-size: 0.75em;
}

.large {
    font-size: 1.3em;
}

.primary {
    border-color: $colorPrimary;

    background-color: $colorPrimary;

    &:not(:disabled):hover {
        @include lighten($colorPrimary);
    }
}

.secondary {
    border-color: $colorPrimary;

    background-color: $colorWhite;

    color: $colorPrimary;

    &:not(:disabled):hover {
        border-color: $colorPrimary;

        box-shadow: $subtleShadow;
    }
}

.success {
    border-color: $colorSuccess;

    background-color: $colorSuccess;

    &:not(:disabled):hover {
        @include lighten($colorSuccess);
    }
}

// .info {
//     background-color: $colorInfo;
//     border-color: $colorInfo;

//     &:not(:disabled):hover {
//         @include lighten($colorInfo);
//     }
// }

.warning {
    border-color: $colorWarning;

    background-color: $colorWarning;

    &:not(:disabled):hover {
        @include lighten($colorWarning);
    }
}

.danger {
    border-color: $colorDanger;

    background-color: $colorDanger;

    &:disabled {
        border-color: $colorGray;

        background-color: $colorGray;
    }

    &:not(:disabled):hover {
        @include lighten($colorDanger);
    }
}

.submit {
    border-color: $colorPrimary;

    background-color: $colorPrimary;

    color: #fff;

    &:not(:disabled):hover {
        @include lighten($colorPrimary);
    }
}

.close {
    transition: fill 0.1s;

    border: none;

    background: transparent;

    fill: $colorGray;

    svg {
        transform: rotate(45deg);

        &:not(:disabled):hover {
            fill: lighten($colorGray, 5%);
        }
    }
}

.linkStyle {
    display: inline-block;

    margin: 0;
    padding: 0;

    transition: all 0.2s;

    border: none;

    background-color: transparent;

    color: $colorGrayDark;
    font-size: 1em;

    &:focus {
        outline: none;

        color: $colorGray;
    }

    &:disabled {
        color: lighten($colorGray, 15%);

        cursor: default;

        filter: none;
    }

    &:not(:disabled):hover {
        transition: all 0.2s;

        border: none;

        background-color: transparent;

        color: $colorGray;
    }
}

.plus {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;
    padding: 0;

    transition: all 0.1s;

    border: none;
    border-radius: 50%;

    background-color: #ededed;

    fill: $colorGray;

    svg {
        margin: 0;
    }

    &:disabled {
        filter: none;
    }

    &:not(:disabled):hover {
        background-color: darken($colorLightGray, 15%);

        svg {
            fill: $colorWhite;
        }
    }
}

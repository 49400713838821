@import "../../../../../lib/styles/variables";

.userCard {
    display: flex;
    flex-direction: row;

    padding: 0;

    .allNamesContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 20rem;
    }

    .picContainer {
        display: flex;
        position: relative;
        align-items: flex-start;
        justify-content: center;

        width: 10rem;
        margin: 2rem;
    }

    .socialIcon {
        position: relative;

        .inputSpacing {
            margin-top: 0;
        }

        svg {
            position: absolute;
            top: 1.1rem;
            left: -2.5rem;

            padding: 0.3rem;

            border-radius: 50%;

            background-color: $colorGray;
        }
    }
}

.thumbnail {
    border-radius: 50%;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dropdowns {
    padding-top: 2rem;
}

.formButton {
    width: 10rem;
    margin-right: 1rem;
}

.buttonContainer {
    display: flex;
    margin-top: 4rem;
}

.hideStateField {
    display: none;
}

.description {
    color: $colorGray;
    font-size: 0.84rem;
    font-style: italic;
}

// Mobile
@media (max-width: 1200px) {
    .userCard {
        flex-direction: column;
        align-items: center;

        margin-left: 2rem;
    }
}

@import "../../../lib/styles/variables";

.primaryRoleSelect {
    :global .fs-select {
        &__control {
            border-bottom: 2px solid $colorPrimary;

            &:hover {
                border-color: lighten($colorPrimary, 15%);
            }

            // &--is-disabled {
            //     border-bottom: none;
            // }
        }

        &__single-value {
            padding: $singleSelectOptionPadding;

            border: $colorPrimary solid 1px;
            border-radius: 1rem;

            background-color: transparent;

            color: $colorPrimary;
            font-size: 0.7rem;

            justify-self: center;
        }

        &__value-container {
            display: flex;
            // flex-direction: row-reverse;

            // max-width: 10rem;
            // min-height: 2rem;
        }

        &__dropdown-indicator svg {
            fill: $colorGray;
        }
    }
}

.contentBox {
    padding: $gutter * 2;
}

.owl-small {
    @include owl(0.2em);
}

.owl-medium {
    @include owl($gutter-small);
}

.owl {
    @include owl($gutter);
}

.owl-large {
    @include owl(calc(#{$gutter} * 1.5));
}

.owl-extra-large {
    @include owl(calc(#{$gutter} * 2));
}

.horizontal-owl-small {
    @include owl(0.2em, true);
}

.horizontal-owl-medium {
    @include owl($gutter-small, true);
}

.horizontal-owl {
    @include owl($gutter, true);
}

.horizontal-owl-large {
    @include owl(calc(#{$gutter} * 1.5), true);
}

.horizontal-owl-extra-large {
    @include owl(calc(#{$gutter} * 2), true);
}

.vertical-middle > * {
    vertical-align: middle;
}

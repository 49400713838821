@import "../../../../../lib/styles/variables";

.expandedSection {
    padding: 1rem 0;

    border-top: 1px solid $colorPrimary;
}

.siteExpandedSection {
    padding-top: 0;
}

.headers {
    text-transform: uppercase;

    strong {
        position: relative;

        &::after {
            content: "";

            position: absolute;
            right: 0;
            bottom: -0.3rem;
            left: 0;

            width: 6rem;
            height: 1px;

            background: $colorFSBlue;
        }
    }
}

.statusRow {
    display: flex;

    width: 500px;
    padding: 0.5rem 0;
    padding-left: 3rem;

    > * {
        flex: 1;
    }
}

.dateHeader {
    display: block;

    margin-top: 1.8rem;
    margin-bottom: 0.5rem;
    padding-left: 3rem;
}

.dateStatusRow {
    @extend .statusRow;

    padding-left: 5rem;
}

.placeholderText {
    padding-left: 3rem;
}

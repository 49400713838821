.myStatsHeader {
    display: flex;
    align-items: center;

    padding: 1rem 0;

    h2 {
        margin: 0 2rem 0 0;
    }
}

.container {
    width: 825px;
}

.paymentsBySite {
    padding: 1rem;
}

@import "../../../lib/styles/variables";

.delete {
    position: absolute;
    z-index: 2;
    top: -5px;
    left: -5px;

    width: 1.3rem;
    height: 1.3rem;
    padding: 0;

    border: 2px solid $colorWhite;
    border-radius: 50%;

    font-size: 0.6rem;

    text-transform: uppercase;
}

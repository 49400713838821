@import "../../../../styles/variables";

.headerNavContainer {
    position: fixed;
    z-index: 1000;

    width: 100%;
    height: $headerNavHeight;

    :global .headroom--pinned,
    :global .headroom--unfixed {
        background-color: transparent;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
            0 3px 3px -2px rgba(0, 0, 0, 0.14),
            0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }

    .headerNav {
        display: flex;

        height: 100%;

        background: #094671;

        svg {
            margin: 0 0.5rem;
        }
    }

    .pageTitle {
        margin-left: 1rem;

        color: $colorWhite;
        font-size: 1.3rem;
        font-weight: 600;

        text-transform: capitalize;
    }

    .active {
        color: $colorWhite;
    }

    .navLeft {
        display: flex;
        align-items: center;

        font-size: 0.9rem;

        .logoContainer {
            display: flex;
            position: relative;
            align-items: center;

            height: 100%;
            margin-right: 2rem;
            padding: 0 0.5rem 0 1rem;

            background: $colorFSBlue;

            &::after {
                content: "";

                position: absolute;
                z-index: 1;
                top: 0;
                right: -1rem;

                width: 2rem;
                height: 100%;

                transform: skewX(-25deg);

                background: $colorFSBlue;
            }
        }

        .pageTitle {
            font-size: 1.2rem;

            text-transform: uppercase;
        }

        .secondaryNav {
            display: flex;
            z-index: 10;

            padding-left: 1rem;

            a {
                margin-left: 2rem;

                opacity: 0.5;

                color: $colorWhite;

                &:hover,
                &.active {
                    opacity: 1;
                }
            }
        }
    }

    .navRight {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0 1rem 0 auto;

        > * + * {
            margin-left: 1rem;
        }
    }

    .userIcon {
        width: 2rem;
        height: 2rem;

        border-radius: 50%;

        cursor: pointer;
    }
}

:global {
    .headroom {
        top: 0;
        right: 0;
        left: 0;

        height: 3.5rem;
    }

    .headroom--unfixed {
        position: relative;

        transform: translateY(0);
    }

    .headroom--scrolled {
        transition: transform 200ms $smoothTransition 0ms;
    }

    .headroom--unpinned {
        position: fixed;

        transform: translateY(-100%);

        transition: transform 200ms $smoothTransition 0ms;
    }

    .headroom--pinned {
        position: fixed;

        transform: translateY(0%);
    }
}

.secondaryNav {
    display: flex;

    a {
        margin-left: 2rem;

        opacity: 0.7;

        color: $colorWhite;

        &:hover,
        &.active {
            opacity: 1;
        }

        .active {
            opacity: 1;
        }
    }
}

@import "./colors";

// Reset Vars
$font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell,
    "Helvetica Neue", sans-serif;
$title-font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell,
    "Helvetica Neue", sans-serif;
$font-size: 15px;
$font-weight: 400;
$text-color: #222;
$disabled-text-color: #aaa;
$anchor-color: $blue;
$line-color: #ccc;

.input {
    padding: 6px 0 7px;

    border-top: none;
    border-right: none;
    border-bottom: 1px solid #999;
    border-left: none;
    outline: 0;

    background-image: none !important;
    box-shadow: none;
}

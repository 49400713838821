@import "../../../../../../styles/variables";

.userIconMenu {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
    padding: 0;

    > a,
    button {
        margin: 0.5rem 1rem;

        color: $colorPrimary;
        white-space: nowrap;

        cursor: pointer;
        all: unset;

        &:hover {
            opacity: 0.8;
        }
    }
}

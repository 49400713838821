@import "../../../lib/styles/variables";

.disclaimer {
    max-width: 55rem;

    color: $colorGrayDark;
    font-size: 0.8rem;
    line-height: 1.5;

    span {
        font-weight: 600;
    }
}

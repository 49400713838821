@import "../../styles/variables";

.modalBackground {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    min-height: 100vh;

    background: rgba(0, 0, 0, 0.2);
}

.relative {
    position: relative;
}

.modalBox {
    max-width: 95vw;
    max-height: 100vh;
    padding: 2rem;

    border-radius: 0.8rem;

    background-color: $colorWhite;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: $mobile) {
        max-width: 80vw;
    }
}

.closeModal {
    display: flex;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;
    margin: 0;
    padding: 0;
}

.hide {
    display: none;
}

@import "../../../styles/variables";

.error {
    position: absolute;
    right: 0;
    left: 0;

    height: 1.2rem;
    padding: 0.5rem 0;

    color: $colorDanger;
}

@import "../../styles/variables";

.alert {
    position: relative;

    margin: 1rem 0;
    padding: 0.75rem 1.25rem;

    border: 1px solid transparent;

    color: $colorWhite;
    line-height: 1.5;
}

.title {
    font-weight: $bold;
}

// overrides default button classes

:global .Toastify ~ .buttonContainer button {
    border-color: $colorGray;

    background-color: $colorWhite;

    color: $colorGray;
}

:global .Toastify {
    &__toast {
        min-width: 21rem;
        padding: 1.5rem;

        border-radius: 5rem;

        font-size: 1.1rem;
        font-weight: $bold;

        &--success {
            border-color: $colorGray;

            background-color: $colorGray;
        }

        &--info {
            border-color: $colorPrimary;

            background-color: $colorPrimary;
        }

        &--warning {
            background-color: $colorWarning;
        }

        &--error {
            background-color: $colorDanger;
        }
    }

    &__close-button {
        padding-left: 1rem;
    }
}

:global .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 27rem;
}

.fade {
    transition: opacity 0.15s linear;
}

@import "../../styles/variables";

.wrapper {
    align-items: center;
    width: 7rem;
    padding: 5px 0;

    border: none;
    border-bottom: 2px solid $colorMediumLightGray;

    color: $colorGrayDark;
}

.dropDownButton {
    position: absolute;
    right: 0;

    padding: 0.5rem;

    border: none;

    background-color: transparent;
}

:global {
    .react-datepicker-popper {
        z-index: 4;
    }

    .react-datepicker__month-text {
        padding: 0.3rem;
    }

    .react-datepicker__month--selected {
        background-color: $colorPrimary !important;
    }

    .react-datepicker__header {
        padding: 8px;
    }

    .react-datepicker__input-container {
        display: flex;

        height: 40px;
    }
}

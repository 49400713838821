@import "../../styles/variables";

// React-Select default styles are not being applied on app-dev.
// Solution: Add styles to our custom classes

.select {
    font-size: 1rem;
}

.label {
    position: absolute;
    z-index: 2;

    transform: translate(0, 0.75rem) scale(1);
    transform-origin: top left;
    transition: color 200ms $smoothTransition 0ms, transform 200ms $smoothTransition 0ms;

    color: $colorGray;

    cursor: pointer;

    &.labelFocused {
        color: $colorPrimary;
    }

    &.hasValue {
        transform: translate(0, -1.5rem) scale(0.75);
    }

    &:hover {
        color: $colorPrimary;
    }
}

.labelDisabled {
    color: $colorLightGray;

    pointer-events: none;
}

:global .fs-select-container {
    position: relative;

    &.focused {
        label {
            transform: translate(0, -1.5rem) scale(0.75);

            color: $colorPrimary;
        }

        &::after {
            content: "";

            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 2px;

            background: $colorPrimary;

            pointer-events: none;
        }
    }

    .fs-select {
        &--is-disabled {
            position: relative;
            // React-Select default style
            pointer-events: none;
        }

        &__single-value {
            position: absolute;
            top: 50%;

            max-width: calc(100% - 8px);
            margin-right: 2px;
            // React-Select default style
            margin-left: 2px;

            overflow: hidden;

            transform: translateY(-50%);

            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__multi-value {
            // React-Select default style
            display: flex;

            min-width: 0;
            margin: 2px;
            padding: $multiSelectOptionPadding;

            border: $colorGray solid 1px;
            border-radius: 1rem;

            background-color: transparent;

            &__label {
                padding: 3px;
                padding-left: 6px;
                overflow: hidden;
                // React-Select default style
                border-radius: 2px;

                color: hsl(0, 0%, 20%);
                font-size: 85%;
                text-overflow: ellipsis;

                white-space: nowrap;
            }

            &__label,
            &__remove {
                margin-left: 0;

                color: $colorGray;
                font-size: 0.7rem;
            }

            &__remove {
                display: flex;
                // React-Select default style
                align-items: center;

                padding-right: 4px;
                padding-left: 4px;

                transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

                border-radius: 2px;

                cursor: pointer;

                svg {
                    // React-Select default style
                    display: inline-block;

                    stroke-width: 0;
                    stroke: currentColor;

                    fill: currentColor;

                    line-height: 1;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        &__input {
            visibility: visible;

            margin: 2px;
            padding-top: 2px;
            padding-bottom: 2px;

            color: hsl(0, 0%, 20%);
        }

        &__placeholder {
            display: none;
        }

        &__indicator-separator {
            display: none;
        }

        &__indicator {
            display: flex;

            padding: 8px;

            transition: color 150ms;
            // React-Select default style
            color: hsl(0, 0%, 80%);

            &:not(:disabled) {
                cursor: pointer;
            }
        }

        &__dropdown-indicator svg {
            fill: $colorMediumLightGray;
        }

        &__value-container {
            display: flex;
            position: relative;
            flex: 1;
            flex-wrap: wrap;
            // React-Select default style
            align-items: center;

            padding: 2px 6px;
            // Ours
            padding-left: 0;
            overflow: hidden;

            cursor: text;
        }

        &__menu {
            z-index: 5;

            border-radius: 0;
        }

        &__menu-list {
            border-radius: 0;
        }

        //  Disable clear all roles
        &__clear-indicator {
            display: none !important;
        }

        &__control {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            // React-Select default style
            align-items: center;
            justify-content: space-between;

            min-height: 38px;

            transition: all 100ms;
            // Ours
            border: 2px solid $colorMediumLightGray;
            border-top: none;
            border-right: none;
            border-left: none;
            border-radius: 0;
            outline: 0 !important;

            background-color: hsl(0, 0%, 100%);
            background-color: transparent;

            cursor: default;

            &--is-disabled {
                opacity: 0.5;

                // .fs-select__dropdown-indicator {
                //     visibility: hidden;
                // }

                :global .fs-select {
                    &__multi-value {
                        &__remove {
                            display: none;
                        }

                        &--is-disabled {
                            padding: $multiSelectOptionPaddingDisabled;

                            &__remove {
                                display: none;
                            }
                        }
                    }
                }
            }

            &:hover {
                border-color: darken($colorMediumLightGray, 10%);

                :global .fs-select__dropdown-indicator svg {
                    fill: darken($colorMediumLightGray, 10%);
                }
            }

            &--is-focused {
                border-bottom: 2px solid $colorPrimary;

                background-color: $colorGrayLight;
                box-shadow: none;

                :global .fs-select__dropdown-indicator svg {
                    fill: $colorPrimary;
                }

                &:hover {
                    border-bottom: 2px solid $colorPrimary;

                    :global .fs-select__dropdown-indicator svg {
                        fill: $colorPrimary;
                    }
                }
            }
        }
    }
}

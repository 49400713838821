@import "../../styles/variables";

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loadingContainer {
    display: flex;
    position: absolute;
    z-index: 3;
    justify-content: center;

    width: calc(100% + 2px);
    height: 100%;

    background: $colorWhite;
}

.verticalCenter {
    align-items: center;
}

.spinner {
    display: flex;
    position: relative;
    top: 3.5rem;
    align-items: center;
    justify-content: center;

    width: var(--size);
    height: var(--size);

    border-radius: 50%;

    background: $colorWhite;

    --size: 80px;

    .backLayer {
        content: "";

        position: absolute;
        z-index: -1;
        top: calc((var(--size) / 9 + 1px) * -1);
        left: calc((var(--size) / 9 + 1px) * -1);

        width: 125%;
        height: 125%;

        animation: rotate 1s linear infinite;

        border-radius: 50%;
    }
}

.verticalCenterSpinner {
    top: -80px;
}

.fsLogo {
    position: relative;

    fill: $colorFSBlue;
}

@import "../../styles/variables";

.unauthorizedLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    img {
        width: 70%;
    }

    h4 {
        margin-top: 0;
    }
}

@import "../../styles/variables";

.box {
    margin-bottom: 1.25rem;

    box-shadow: $subtleShadow;
}

.boxContent {
    height: 100%;
    padding: 1em 2em;

    &.background {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.boxHeader {
    padding: 1.5em 2em;

    background-color: rgba(0, 0, 0, 0.05);

    h2 {
        margin-bottom: 0;

        font-size: 1em;
        font-weight: $bold;

        text-transform: uppercase;
    }
}

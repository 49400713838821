/**
 * Colors
 **/

$colorWhite: #fff;
$colorOffWhite: #f6f6f6;
$colorGray: #999;
$colorMediumLightGray: #ccc;
$colorLightGray: #e5e5e5;
$colorGrayLight: rgba(0, 0, 0, 0.05);
$colorGrayDark: #333;
$textColor: #222;
$colorFSBlue: #073759;
$colorPrimary: #5a97c1;
$colorSuccess: #5ea029;
// $colorInfo: #0e5487;
$colorWarning: #f7941d;
$colorDanger: #bf202e;
$colorLink: #1a73e8;

/**
 * Font Weights
 **/

$light: 300;
$normal: 400;
$semiBold: 500;
$bold: 600;
$extraBold: 800;

/**
 * Widths
 **/
$mobile: 480px;
$tablet: 767px;
$paymentsTableWidth: 1265px;
$usersTableWidth: 1250px;
$userDetailFrameWidth: 700px;

/**
 * Heights
 **/
$headerNavHeight: 3.5rem;
$sideNavHeight: calc(100% - #{$headerNavHeight});
$headerHeight: 4.5rem;

/**
 * Gutters
 **/
$gutter: 1rem;
$gutter-small: 0.5rem;
$gutterContentTop: $headerHeight;

/**
 * Padding
 **/
$contentPadding: $gutter * 2;
$singleSelectOptionPadding: 0.4rem 1.1rem;
$multiSelectOptionPadding: 0.25rem 0.5rem 0.25rem 0.7rem;
$multiSelectOptionPaddingDisabled: 0.25rem 0.7rem;

/**
 * Animation
 **/

$smoothTransition: cubic-bezier(0, 0, 0.2, 1);

/**
 * Animation
 **/
$subtleShadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);

@import "../../../lib/styles/variables";

.modal {
    width: 450px;
    padding: 3rem;

    input {
        width: 20rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.changePasswordInput {
    --wrapperPadding: 1.8rem;
}

.submit {
    display: block;

    margin: 2.5rem auto 0;
}

.requirementsList {
    display: none;

    height: 0;
    margin: 0;
    padding: 0;

    border: none;

    &.show {
        display: block;

        height: 7.2rem;
        padding: 15px;

        transition: height 50ms ease-in, opacity 0.3s ease-in;

        border: 2px solid $colorGray;
        border-radius: 10px;

        background-color: $colorGray;

        font-size: 0.8rem;

        padding-inline-start: 35px;
    }

    li {
        list-style-type: disc;

        color: $colorWhite;
        line-height: 1.2rem;
    }
}

@media (min-width: 850px) {
    div.requirements {
        position: relative;

        .requirementsList {
            opacity: 0;

            &.show {
                position: absolute;
                z-index: 2;
                top: -28px;
                right: -215px;

                width: 14rem;
                height: 7rem;

                transition: height 50ms ease-in, opacity 0.2s ease-in;

                opacity: 1;

                font-size: 0.8rem;

                &::after {
                    content: "";

                    position: absolute;
                    top: 50%;
                    left: 0;

                    width: 0;
                    height: 0;
                    margin-top: -29px;
                    margin-left: -20px;

                    border: 25px solid transparent;
                    border-bottom: 0;
                    border-left: 0;
                    border-right-color: $colorGray;
                }
            }
        }
    }
}

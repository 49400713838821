@import "../../styles/variables";

.frame {
    display: flex;
    flex-direction: column;

    border: 1px solid $colorLightGray;
    border-radius: 0.5rem;

    --paddingSides: 1.5rem;
    --paddingVertical: 1rem;
}

.heading {
    margin-bottom: 0.5rem;
}

.frame + * {
    margin-top: $gutter;
}

.oneChild {
    padding: var(--paddingSides);
}

.textSection {
    padding: var(--paddingSides);
    padding-bottom: var(--paddingVertical);
}

.buttonSection {
    @extend .oneChild;

    border-top: 1px solid $colorLightGray;
}

.multiButtonSection {
    @extend .oneChild;

    display: flex;
    flex-direction: column;
}

.multiButtons {
    padding: var(--paddingVertical) 0;

    &:last-child {
        padding-bottom: 0;
    }

    &::before {
        content: "";

        display: block;
        position: relative;
        top: -1rem;

        width: calc(100% + var(--paddingSides));
        height: 1px;

        background: $colorLightGray;
    }
}

.roleSelectIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;

    border-radius: 50%;

    background-color: #ededed;

    cursor: pointer;
}

@import "../../../lib/styles/variables";

.container {
    border-bottom: 1px solid $colorGray;
}

.inner {
    display: flex;
    align-items: center;

    padding: 1rem 2rem;

    :global .fs-select-container {
        width: 13rem;
        margin-right: 1rem;
    }
}

.site {
    display: flex;
    align-items: center;
}

.cycleSitesButtons {
    height: 1.6rem;

    border: 1px solid gray;
    border-radius: 0.5rem 0 0 0.5rem;

    svg {
        transform: rotate(90deg);
    }

    &.next {
        border-radius: 0 0.5rem 0.5rem 0;

        svg {
            transform: rotate(-90deg);
        }
    }

    &:hover {
        background: $colorGrayLight;
    }

    &:disabled {
        cursor: default;
    }

    &:not(:disabled):hover {
        cursor: pointer;
    }
}

.iconContainer {
    flex: 1;
}

.usersStatsHeaderContainer {
    display: flex;
    align-items: center;

    div {
        img {
            border-radius: 50%;
        }
    }
}

.headerName {
    margin-left: 1rem;
}

@import "../../styles/variables";

.paper {
    position: absolute;

    min-width: 16px;
    max-width: calc(100% - 32px);
    min-height: 16px;
    max-height: calc(100% - 32px);
    margin-top: 1rem;
    padding: 1rem 0.75rem;

    overflow-x: visible !important;
    overflow-y: visible !important;

    outline: none;

    background: white;
}

@import "../../styles/variables";

.thumbnail {
    display: inline-block;

    max-width: 100%;
    height: auto;

    background-color: $colorGray;

    line-height: 1.42857143;
}

@import "../../styles/variables";

.buttonGroup {
    display: flex;

    margin-bottom: 1rem;

    cursor: pointer;

    & button {
        border-width: 1px;
        border-color: $colorWhite;

        background-color: $colorPrimary;

        &:focus {
            z-index: 2;
        }
    }

    & button:first-child {
        border-radius: 2em 0 0 2em;
    }

    & button:last-child {
        border-radius: 0 2em 2em 0;
    }

    & button:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

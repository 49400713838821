@import "../../styles/variables";

.checkbox {
    opacity: 0;

    + label {
        position: relative;

        padding-left: 1rem;

        transition: color 0.2s;

        cursor: pointer;

        &:hover {
            color: $colorPrimary;
        }

        &::before {
            content: "";

            display: inline-block;
            position: absolute;
            top: 0.1em;
            left: -1em;

            width: 1.1em;
            height: 1.1em;

            background-color: #ccc;

            cursor: pointer;
        }

        &::after {
            content: none;

            display: inline-block;
            position: absolute;
            top: 0.35em;
            left: -0.7em;

            width: 9px;
            height: 6px;

            transform: rotate(-45deg);

            border-bottom: 2px solid;
            border-left: 2px solid;

            color: $colorWhite;

            cursor: pointer;
        }
    }

    &:checked {
        + label::after {
            content: "";
        }
    }

    &:focus {
        + label::before {
            outline: $colorGray auto 4px;
        }
    }

    &:disabled {
        cursor: default;
    }
}

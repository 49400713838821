.postNumber {
    min-width: 4.5em !important;

    text-align: right;
}

.confine {
    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap;
}

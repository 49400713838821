@import "../../styles/vars";
@import "../../../lib/styles/variables";

.primaryHeader {
    display: flex;
    position: relative;
    // Must be higher than ten to allow dropdown menus to be z above react table
    z-index: 11;

    min-width: calc(1265px + 4rem);
    height: $headerHeight;
    padding: $gutter $gutter * 2;

    border-bottom: 2px solid hsl(0, 0%, 80%);

    background-color: #fff;
}

.filterDropdowns {
    display: flex;
    flex: 2;

    > div {
        flex: 1 0 100px;
    }
}

.site {
    display: flex;
    align-items: center;

    > div:first-of-type {
        width: 12rem;
    }
}

.fullSite {
    display: flex;
    align-items: center;

    > div:first-of-type {
        width: 100%;
    }
}

.cycleTooltip {
    position: relative;
    top: 3px;

    &:not(:last-of-type) {
        margin: 0 0 0 0.5rem;
    }
}

.cycleSitesButtons {
    width: 100%;
    height: 1.6rem;

    border: 1px solid gray;
    border-radius: 0.5rem 0 0 0.5rem;

    svg {
        transform: rotate(90deg);
    }

    &.next {
        border-radius: 0 0.5rem 0.5rem 0;

        svg {
            transform: rotate(-90deg);
        }
    }

    &:hover {
        background: $colorGrayLight;
    }

    &:disabled {
        cursor: default;
    }

    &:not(:disabled):hover {
        cursor: pointer;
    }
}

.filterName {
    display: flex;
    align-items: center;

    width: 11em;

    font-size: 0.9rem;

    .filterNameInput {
        position: relative;
        margin: 0;

        --wrapperPadding: 0.4rem;
    }
    
    .filterNameInputFullWidth {
        width: 100%;
    }
}

.clearFilters {
    button {
        padding: 0.5em 1em;
    }
}

@import "../../styles/variables";

.imageUpload {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 1rem;

    padding: 0.2rem 0.5rem 0.5rem 0.5rem;

    border-color: $colorWhite;

    background-color: $colorLightGray;

    .label {
        pointer-events: none;

        svg {
            transition: fill 0.2s;

            vertical-align: middle;
        }
    }

    &:hover {
        label {
            svg {
                fill: $colorWhite;
            }
        }
    }

    &:disabled {
        filter: brightness(100%);

        label {
            filter: brightness(120%);
        }

        &:hover {
            label {
                svg {
                    fill: $colorGray;
                }
            }
        }
    }
}

.input {
    position: absolute;
    z-index: -1;

    width: 0.1px;
    height: 0.1px;

    overflow: hidden;

    opacity: 0;

    &:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.spinner {
    display: inline-block;
    position: relative;
    top: 0.3rem;

    width: 1em;
    height: 1.75em;
    margin-right: 0.5rem;
}

@mixin spinner-span {
    display: inline-block;

    width: 0.3em;
    height: 0.3em;

    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    border-radius: 50%;

    background-color: $colorPrimary;
}

.spinner .bounce1 {
    @include spinner-span;

    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    @include spinner-span;

    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.spinner .bounce3 {
    @include spinner-span;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.modalContainer {
    position: absolute;
    z-index: 10;
    top: 2.4rem;
    left: 7rem;
    padding: 0.8rem 2rem 0.8rem 0.8rem;

    border-radius: 0.7rem;

    background-color: $colorWhite;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
        3px 3px 3px 0 rgba(0, 0, 0, 0.14),
        0 1px 8px 0 rgba(0, 0, 0, 0.12);

    .button {
        padding: 0.3rem;

        border: none;

        background-color: transparent;

        color: $textColor;
        font-weight: 600;

        &:hover {
            border: none;

            background-color: transparent;

            color: $colorGray;
        }
    }
}

.hidden {
    display: none;
}

@import "../../styles/variables";

.container {
    flex: 1;

    img {
        object-fit: cover;
    }
}

.loading {
    filter: blur(5px);
}

@import "../../styles/variables";

:global .ReactTable.fsTable {
    border: none;

    box-shadow: none;

    // Allows dropdown to overflow table

    .rt-table,
    .rt-tbody {
        overflow: visible !important;
    }

    .rt-td {
        padding: 0.5rem 0.7rem;
    }

    .rt-td,
    .-pagination {
        box-shadow: none !important;

        font-size: 0.9rem;
    }

    .rt-tfoot {
        box-shadow: none !important;
    }

    .-headerGroups {
        .rt-tr {
            .rt-th {
                color: $colorGrayDark;

                &:not(:last-child) {
                    border-right: 1px solid $colorGray;
                }

                &:last-child {
                    border-right: none !important;
                }
            }
        }
    }

    .rt-thead {
        .rt-tr {
            text-align: left;
        }

        .rt-th {
            padding: 0.7rem;

            border-right: 1px solid #ccc;

            color: $colorWhite;
            font-size: 0.8rem;
            font-weight: 800;

            letter-spacing: 0.01rem;
            text-transform: uppercase;

            &.sortable {
                &::before {
                    content: "\2195";

                    padding-left: 0.5rem;
                    float: right;

                    transform: scale(1.2);
                }

                &.-sort-desc {
                    box-shadow: none !important;

                    &::before {
                        content: "\2193";

                        transition: all 0.5s ease;
                    }
                }

                &.-sort-asc {
                    box-shadow: none !important;

                    &::before {
                        content: "\2191";
                    }
                }
            }
        }

        &.-header {
            background-color: lighten($colorGrayDark, 5%);
            box-shadow: none !important;
        }

        &.-filters input {
            padding-left: 0;

            border: 0;

            &::placeholder {
                color: lighten($colorGray, 20%);
            }
        }
    }

    &.subTable {
        margin: 1rem;

        border: 1px solid $colorLightGray;
        border-radius: 0.5rem;

        .rt-table {
            overflow: hidden !important;

            border-radius: 0.4rem; // a gap is visible in the corners at 0.5
        }

        .rt-thead.-header {
            background-color: $colorLightGray;
        }

        .rt-thead .rt-th {
            color: $colorGray;
        }
    }

    .-loading {
        // Mobile is showing top and side of table behind loading div. Solution:
        top: -1px;
        left: -1px;

        width: calc(100% + 2px);

        background: #fff;
    }
}

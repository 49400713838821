@mixin button-reset() {
    display: inline-block;

    margin: 0;
    padding: 0 !important;

    border: none !important;

    background-color: transparent !important;

    color: $textColor;
    font-size: 1em;
}

// For ol and ul

@mixin list-reset() {
    margin-right: 0;
    margin-left: 0;
    padding: 0;

    // For nested ol's and ul's
    ol,
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
    }
}

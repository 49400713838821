@import "../../styles/vars";

.trDetailView {
    margin: 1em;
    padding: 1em;

    border: 1px solid #eee;
    border-radius: 0.5em;

    background-color: #fafafa;

    font-size: 0.9rem;
    line-height: 1.5;
}

.headerExpanded {
    font-weight: 700;

    letter-spacing: 0;
}

.columns > * {
    position: relative;

    + *::before {
        content: "";

        display: block;
        position: absolute;
        top: 0;
        left: -1em;

        width: 2px;
        height: 100%;

        border-left: 1px solid $line-color;
    }
}

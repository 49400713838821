@import "../../styles/variables";
// Modal styles

.modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h4 {
        padding: 0 0.5rem;

        text-align: center;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-around;

        .buttonYes {
            border-color: transparent;

            background-color: rgba(0, 0, 0, 0.25);

            white-space: nowrap;

            &:hover {
                border-color: rgba(0, 0, 0, 0.25);

                background-color: transparent;
            }
        }

        .buttonNo {
            border-color: rgba(0, 0, 0, 0.25);

            background-color: transparent;

            white-space: nowrap;

            &:hover {
                border-color: transparent;

                background-color: rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.deleteSite {
    width: auto;

    border-radius: 10px;

    background-color: $colorWarning;

    color: $colorWhite;
}

.deleteUser {
    width: auto;

    border-radius: 10px;

    background-color: $colorDanger;

    color: $colorWhite;
}

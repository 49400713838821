@import "./variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-family: inherit;
    font-weight: $semiBold;

    letter-spacing: 1px;
    line-height: 1.1;

    &:first-child {
        margin-top: 0;
    }
}

p {
    margin-bottom: 1rem;
}

li {
    list-style: none;
}

a {
    transition: all 0.2s;

    color: $colorGrayDark;
    text-decoration: none;

    &:hover {
        color: $colorGray;
    }
}

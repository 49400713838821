@import "../../styles/colors.scss";
@import "../../../lib/styles/variables";

.select {
    width: 100%;

    border: none;

    -webkit-appearance: none;
    appearance: none;

    background-color: transparent;

    color: $blue;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        color: $colorGray;
    }
}

@import "../../../../../../../lib/styles/variables";

.plainMode {
    display: flex;
    align-items: center;

    color: $colorFSBlue;

    .icon {
        margin-left: auto;
    }

    &:not(:disabled):hover {
        cursor: pointer;

        filter: brightness(120%);
    }
}

.form {
    display: inline-block;

    height: 1.3rem;
}

.input {
    width: 10rem;
    padding: 0.2em;

    border: none;
    outline: none;

    background-color: #e4f2fc;

    color: $colorFSBlue;

    text-align: left;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }

    &:disabled {
        color: $colorGrayLight;
    }
}

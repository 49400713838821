@import "../../../lib/styles/variables";

.container {
    max-width: 1160px;
}

.siteStats {
    display: flex;

    margin-top: 2rem;

    > * {
        display: flex;
        flex: 1 0 20%;
        flex-direction: column;
        justify-content: space-evenly;

        margin-right: 2rem;
        padding: 0.5rem;

        border-top: 0.25rem solid #e5e5e5;

        background-color: $colorOffWhite;

        color: $colorFSBlue;

        text-align: center;
        text-transform: uppercase;
    }

    .pageViews {
        flex-grow: 2;

        margin-right: 0;
    }
}

.tiers {
    display: flex;
    justify-content: space-around;

    margin-top: 1rem;

    .stat {
        padding-top: 0.3rem;
    }
}

.stat {
    font-size: 1.4rem;
    font-weight: 600;
}

.tableContainer {
    width: 1010px;
    margin-top: 3rem;
}

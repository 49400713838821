@import "../../styles/vars";
@import "../../../lib/styles/variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 15rem;
    margin: 0 auto;
}

.titleContainer {
    display: flex;
    position: relative;
    align-items: center;

    width: 100%;
    padding: 2rem;

    text-align: center;
}

.title {
    margin: auto;
}

.backButton {
    position: absolute;
    left: -4rem;

    width: 2.5rem;
    height: 2.5rem;

    background: $colorGrayLight;

    color: $colorFSBlue;
    font-size: 1.5rem;

    &:not(:disabled):hover {
        background: $colorLightGray;
    }

    & a:hover {
        color: $colorFSBlue;
    }
}

.invoiceForm {
    display: flex;
    flex-direction: column;

    width: 100%;

    .labels {
        top: -1rem;
        left: -10.5rem;

        width: 150px;

        text-align: right;
    }

    .commentsTextarea {
        top: 1rem;

        padding: 0;

        transform: none;
    }
}

.wrapper {
    --wrapperPadding: 0.5rem;
}

.optionalInfo {
    display: flex;
    position: relative;
    left: 0.5rem;
    flex-direction: column;

    .labels {
        top: -0.8rem;
    }

    h3 {
        position: relative;
        top: 1rem;
        left: -4rem;
    }

    > div {
        width: 100px;
    }
}

.inputs {
    &:focus-within {
        .labels {
            color: $colorPrimary;
        }

        .commentTextarea {
            color: $colorPrimary;
        }
    }
}

.dropdowns {
    padding: 1.5rem 0;

    .dropdownLabels {
        top: 1.5rem;
    }
}

.submit {
    width: 11rem;
    margin: 3rem 0;
}

@media only screen and (max-width: 1100px) {
    .form,
    .title {
        width: 100%;
    }
}

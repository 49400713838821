@import "../../../../lib/styles/variables";

.addSiteButton {
    display: flex;
    align-items: center;

    .addSiteText {
        margin-left: 0.5rem;

        transition: all 0.2s;

        color: $colorGray;
        font-size: 0.9rem;
    }

    &:hover {
        button:not(:disabled) {
            background-color: darken($colorLightGray, 15%);

            svg {
                fill: $colorWhite;
            }
        }

        button:not(:disabled) ~ label {
            color: darken($colorGray, 15%);

            cursor: pointer;
        }
    }
}

.addSiteContainer {
    display: flex;
    position: relative;
    align-items: center;

    width: $usersTableWidth;
    height: 80px;

    > * + * {
        margin-left: 1.5rem;
    }

    button {
        text-transform: uppercase;
        white-space: nowrap;
    }

    .roleSelectIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 2em;
        height: 2em;

        border-radius: 50%;

        background-color: #ededed;
    }

    .label {
        margin-top: 0.2rem;
    }
}

.dropdown {
    min-width: 15rem;
    max-width: 35rem;

    :global .fs-select {
        &__control {
            padding: 0 0.5rem;

            border: none;

            &--is-focused {
                border-bottom: none;

                &:hover {
                    border-bottom: none;
                }
            }
        }

        &__multi-value {
            border: $colorGray solid 1px;
            border-radius: 15px;

            background-color: transparent;

            font-size: 0.85rem;
        }

        &__multi-value__label,
        &__multi-value__remove {
            color: $colorGray;
        }

        &__value-container {
            display: flex;
        }

        &__dropdown-indicator svg {
            fill: $colorGray;
        }
    }
}

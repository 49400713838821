@import "../../styles/variables";

.container {
    position: relative;
}

.button {
    position: absolute;
    top: 1.3rem;
    right: 0;

    padding: 0.1rem 0.5rem;
}

// .x {
//     font-weight: 800;

//     top: 1.4rem;
//     right: 3rem;

//     color: #aaa;
// }

@import "../../../lib/styles/variables";

.viewButtons {
    a {
        opacity: 0.5;

        color: $colorWhite;
    }

    .active {
        opacity: 1;
    }
}
